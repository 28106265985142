import {Controller} from "@hotwired/stimulus";
import trello_ctx from "../powerup/trello_ctx";
import server from "../powerup/remote_data";

const CB_SCRIPT = 'https://js.chargebee.com/v2/chargebee.js';

export default class extends Controller {

    async connect() {

        this.checkout_url = this.data.get('checkoutUrl');
        this.portal_url = this.data.get('portalUrl');
        this.subscription_url = this.data.get('subscriptionUrl');
        this.cb_site = this.data.get('cbSite');

        const $script = await import("scriptjs");
        const dd = $script.default;

        dd(CB_SCRIPT, _ => {
            this.chargebeeInstance = Chargebee.init({
                site: this.cb_site,
            });

            $(this.element).trigger('cb:ready');
        })
    }

    checkout(event) {

        this.chargebeeInstance.openCheckout({
            hostedPage: async _ => {
                const t = trello_ctx();
                const response = await server(t).ajax(this.checkout_url);
                if (response.ok) {
                    const result = await response.json();
                    return result;
                }
                return null;
            },
            success: (hostedPageId) => {
                if (this.subscription_url) {
                    const t = trello_ctx();
                    const url = `${this.subscription_url}?hosted_page_id=${hostedPageId}`
                    server(t).ajax(url, {method: 'POST'});
                }
            },
            close: this._on_cb_close.bind(this),
            step: (currentStep) => {
                console.log('checkout navigated to', currentStep);
            }

        });
    }


    portal(event) {
        this.chargebeeInstance.setPortalSession( async _ => {
            const t = trello_ctx();
            const response = await server(t).ajax(this.portal_url);
            if (response.ok) {
                const result = await response.json();
                return result;
            }
            return null;
            });

        const cbPortal = this.chargebeeInstance.createChargebeePortal();
        cbPortal.open({
            close: this._on_cb_close.bind(this)
        });
    }


    _on_cb_close() {
        $(this.element).trigger('cb:closed');
        const t = trello_ctx();
        t.closeModal();
    }
}