import server_data from "../remote_data";
import logger from "../logger";
import summary from "../summary";
import cog from '@fortawesome/fontawesome-free/svgs/solid/gear.svg';
import {cached_cards} from "./cards_cache";
import FieldFormatter from "../field_formatter";



export default async function (t, options) {
    logger.log('>>> card-badges', t.getContext().card);
    try {
        return _card_badges(t, options);
    }
    finally {
        logger.log('<<< card-badges', t.getContext().card);
    }
}


const CARD_POSITION = 'card_pos';

 async function _card_badges(t, options) {
    const [board_data, card_data] = await server_data(t).get_card_data();
    if (!board_data) {
        return [
            {
                icon: window.TrelloPowerUp.util.relativeUrl(cog),
                text: t.localizeKey("enable_p"),
                color: 'purple',
            }
        ];
    }

    const hide_empty = board_data.hasOwnProperty('hide_empty') ?
        board_data.hide_empty : true;

    let defs = board_data.vars;
    let result = [];

    //////


     if (board_data.summary_top_card ) {
         const [ccl, list] = await Promise.all([
             cached_cards(t),
             t.list('id')
         ]);


         const lli = ccl[list.id];

         // const ll = await t.list('all');
         const this_list = list.id;
         // const cc = ll.cards;

         const cid = t.getContext().card;
         // const this_pos = cc.findIndex((e) => e.id == cid);
         const is_first = (lli.top_card_id ==cid);

         // get or set card position
         let card_pos = await t.get('card', 'shared', CARD_POSITION);
         if (!card_pos) {
             console.log('setting pos')
             card_pos = {list: this_list, first: is_first}
             await t.set('card', 'shared', CARD_POSITION, card_pos);
         }

         const need_to_update_pos =
             (is_first != card_pos.first)
             || (this_list != card_pos.list)
         ;


         if (need_to_update_pos) {
             // update to a new position
             card_pos = {list: this_list, first: is_first}
             await t.set('card', 'shared', CARD_POSITION, card_pos);

             // refresh all lists top cards
             for(const lst_id in ccl) {
                 const lst = ccl[lst_id];
                 t.set(lst.top_card_id, 'shared', '_sf_capabilities_trigger', new Date().getTime());
             }
         }
         else {

             if (is_first) {
                 const summary_badges = await summary(t).card_badges();
                 summary_badges.forEach(badge=>result.push(badge));
             }
         }

     }


    ////


    const number_formatter = new Intl.NumberFormat(window.locale);
    const field_formatter = new FieldFormatter();
    for (let i = 0; i < defs.order.length; i++) {
        let vd = defs.order[i];

        let def = defs[vd];

        if (!def.options.show_on_badge) {
            continue;
        }

        let variable;
        if (card_data) {
            variable = card_data[vd];
        }


        let txt = def.l;
        let color = def.c;

        if (variable) {
            const value = field_formatter.getCardBadge(def, variable);
            txt = def.l + ': ' + value;
            color = (typeof variable.c == "undefined") ? def.c : variable.c;
        }

        if ((variable && variable.v) || !hide_empty) {
            result.push({
                text: txt,
                color: color,
            });
        }
    }
    return result;
}
