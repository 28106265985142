import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../trello_ctx";
import Template from '../template';
import PluginData from "../plugin_data";
import ui from "../ui";

export default class extends Controller {

    connect() {
        // nop
    }

    introduction(event) {
        const t = trello_ctx();
        ui(t).intro();
    }

    formulas(event) {
        const t = trello_ctx();
        t.closeBoardBar();
        ui(t).formulas();
    }


    async after_create(event) {
        // reload board data
        // reset template id
        //
        const t = trello_ctx();
        const pd = new PluginData(t);
        const tpl = new Template(t);

        await Promise.all([pd.reload_board(), tpl.reset()]);

        t.closeModal(event)
    }

    reload_all() {
        const t = trello_ctx();
        const pd = new PluginData(t);
        pd.reload_all();
    }

}