export default function routes(t) {

    const card = t.getContext().card;
    const board = t.getContext().board;
    const member = t.getContext().member;

    const _API_BASE     = `/${window.locale}/api`;

    return  {
        member:         () => `${_API_BASE}/members/${member}`,
        card:           () => `${_API_BASE}/boards/${board}/cards/${card}`,
        card_sync:      () => `${_API_BASE}/boards/${board}/cards/${card}/sync`,
        card_history:   () => `${_API_BASE}/boards/${board}/cards/${card}/history`,
        card_last_tx:   () => `${_API_BASE}/boards/${board}/cards/${card}/last_tx`,
        board_list_sorters:    () => `/board/sorters.json`,
        data_entry: () => `/${window.locale}/powerup/edit`,

        board_enabled:  () => `/${window.locale}/board/report_enabled`,
        iframe_url,
        new_transaction: () => iframe_url(`board/cards/${card}/transactions/new`),

        board: () =>`/${window.locale}/boards`,

        // legacy routes probably ok to remove after Nov 2020
        //
        board_admin:    () => `api/boards/${board}/configure`,
    };

    function iframe_url(url, with_locale=true) {
        if (with_locale) {
            return `/powerup/iframe?to=/${window.locale}/${encodeURIComponent(url)}`;
        }
        else {
            return `/powerup/iframe?to=${encodeURIComponent(url)}`;
        }

    }
}

