import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../powerup/trello_ctx";
import PluginData from "../powerup/plugin_data";

export default class extends Controller {

    #t = trello_ctx();
    #plugin_data = new PluginData(this.#t);

    connect() {
        const reload_on_connect = this.data.get('reload');
        if (reload_on_connect) {
            this.reload_all();
        }
    }

    async reload_board(e) {
        e.preventDefault();

        await this.#plugin_data.reload_board();
        $(document).trigger('cft:trello-data-reloaded');
    }

    async trigger_capabilities(e) {
        e.preventDefault();

        await this.#plugin_data.trigger_capabilities();
        $(document).trigger('cft:trello-data-reloaded');
    }

    async reload_all(e) {
        e.preventDefault();
        await this.#plugin_data.reload_all();
    }
}