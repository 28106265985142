/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// authorize trello account
//

import trello_ctx from "../powerup/trello_ctx";
import server from "../powerup/remote_data";

onmount('[data-js-auth-trello]', function() {
  const $btn = $(this);

  // get parameters
  const trello_api_key = $btn.data('trello-api-key');
  const trello_authorized_url = $btn.data('trello-authorized-url');

  const create_session_url = $btn.data('create-session-url');
  const detination_url = $btn.data('destination-url');

  // create trello authorization url
  const trelloAuthParams = {
    expiration: 'never',
    name: 'SmartFields',
    scope: 'read,write,account',
    key: trello_api_key,
    callback_method: 'fragment',
    return_url: trello_authorized_url
  };
  const trelloAuthUrl = `https://trello.com/1/authorize?${new URLSearchParams(trelloAuthParams).toString()}`;


  $btn.on('click', function(event) {
    event.preventDefault();
    const t = window.TrelloPowerUp.iframe();
    t.authorize(trelloAuthUrl, {height: 750, width: 620})
        .then(token => {
              //create session
              //
              Rails.ajax({
                type: 'POST',
                url: create_session_url,
                data: "token=" + token,
                success: async _ => {
                  const t = trello_ctx();
                  const response = await server(t).ajax(detination_url);
                  if (response.ok) {
                    const result = await response.text();
                    const iframe_container = document.querySelector('.js-trello-iframe-container');
                    iframe_container.innerHTML = result;
                    $('.js-trello-iframe-container').children('script').each((index, element) => eval(element.innerHTML));
                    onmount();
                    t.sizeTo(iframe_container);
                  }
                }
              });
              amplitude.getInstance().logEvent('trello.authorize.allowed')
        });
    amplitude.getInstance().logEvent('trello.authorize.clicked')
  });
});


// this is called from the trello_autorized page
// to resolve the t.authorize() promise
window['on_trello_authorized'] = function() {
  let e;
  const token = window.location.hash.substring(7);
  let authorize = undefined;
  try {
    if (window.opener && (typeof window.opener.authorize === 'function')) {
      ({
        authorize
      } = window.opener);
    }
  } catch (error) { e = error; }

  // Security settings are preventing this, fall back to local storage.
  if (authorize) {
    try {
      authorize(token);
    } catch (error1) {
      // localStorage only works when the opened window's domain is
      // the same as the domain of the Power-Up iframe that opened
      // the window.
      e = error1;
      localStorage.setItem('token', token);
    }
  } else {
    localStorage.setItem('token', token);
  }
  // This window.close() may not work if this page is inside an
  // iframe. In this case, use the `windowCallback` option in
  // `t.authorize()` to get access to the window handler when
  // `t.authorize()` is called.
  return window.close();
};
