import { Controller } from "@hotwired/stimulus"
import sortable from 'html5sortable/dist/html5sortable.es'
import trello_ctx from "../powerup/trello_ctx";
import server from "../powerup/remote_data";

export default class extends Controller {


    connect() {

        const $s = this.element;

        sortable($s, {
            placeholderClass: 'bg-purple-400 rounded mr-2',
            forcePlaceholderSize: true,
        })

        $s.addEventListener('sortupdate', async e => {
            const ss = sortable($s, 'serialize')[0];
            const params = ss.items.map( item => item.node.id);

            const url = this.data.get('url');

            const t = trello_ctx();
            const response = await server(t).ajax(url, {
                method: 'PUT',
                headers: {"Content-Type": "application/json; charset=utf-8"},
                body: JSON.stringify({fields: params})
            });

            if (response.ok) {
                $(document).trigger('cft:field-list-changed');
            }
        });
    }

}