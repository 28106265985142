export default class FieldFormatter {

    #number_formatter;
    #short_date_formatter;
    #date_formatter;

    constructor() {
        this.#number_formatter = new Intl.NumberFormat(window.locale);
        this.#short_date_formatter = new Intl.DateTimeFormat(window.locale,
            {
                dateStyle: 'medium',
            }
        );
        this.#date_formatter = new Intl.DateTimeFormat(window.locale,
            {
                dateStyle: 'medium',
                timeStyle: 'short'
            }
        );

    }

    getCardBadge(def, variable) {
        let result = '';
        if (!variable.v) {
            return '';
        }
        if ('text' == def.t) {
            result = variable.v;
        }
        else if ('number' == def.t) {
            result = this.#number_formatter.format(variable.v)
        }
        else if ('date' == def.t) {
            const date = new Date(variable.v);
            result = this.#short_date_formatter.format(date)
        }
        else if ('checkbox' == def.t) {
            result = '0' == variable.v  ? '' : '\u2713';
        }
        return result;
    }


    getCardDetailBadge(def, variable) {
        let result = '';
        if (null == variable.v) {
            return '';
        }
        if ('text' == def.t) {
            result = variable.v;
        }
        else if ('number' == def.t) {
            result = this.#number_formatter.format(variable.v)
        }
        else if ('date' == def.t) {
            const date = new Date(variable.v);
            result = this.#date_formatter.format(date)
        }
        else if ('checkbox' == def.t) {
            result = '0' == variable.v  ? '' : '\u2714';
        }


        return result;
    }


}
