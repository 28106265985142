import routes from './routes'
import Template from './template'

export default function ui(t) {
    return new UI(t);
}

class UI {
    constructor(t) {
        this.t = t;
        this._routes =  routes(t);
    }

    board_menu() {
        this.t.popup({
            title: this.t.localizeKey("setting_title"),
            url: this._routes.iframe_url('board/menu'),
            height: 220,
        });
    }

    card_menu() {
        this.t.popup({
            title: this.t.localizeKey("setting_title"),
            url: this._routes.iframe_url('board/card_menu'),
            height: 220,
        });
    }

    new_field() {
        this.t.popup({
            title: "New field",
            url: this._routes.iframe_url('fields/new'),
            height: 220,
        });
    }


    card_settings() {
        this.t.popup({
            title: "Card Settings",
            url: this._routes.iframe_url('board/edit_appearance'),
        });
    }


    list_summary() {
        this.t.popup({
            title: "Configure list summary",
            url: this._routes.iframe_url('board/edit_list_summary'),
        });
    }

    list_sorters() {
        this.t.popup({
            title: "List Sorters",
            url: this._routes.iframe_url('board/sorters.html'),
        });
    }


    formulas() {
        this.t.modal({
            title: "Edit Smart Field Formulas",
            url: this._routes.iframe_url('formulas/edit'),
            fullscreen: true,
        });
    }


    async intro() {
        const tpl = new Template(this.t);
        const tpl_id = await tpl.id;

        const with_tpl = tpl_id && tpl_id != this.t.getContext().board;
        const url = with_tpl ? `boards/new?template_id=${tpl_id}` : `boards/new`
        this.t.modal({
            title: this.t.localizeKey('intro_title'),
            url: this._routes.iframe_url(url),
            fullscreen: false,
        });
    }


    data_entry() {
        this.t.closePopup()
        this.t.popup({
            title: this.t.localizeKey('data_entry'),
            url: this._routes.new_transaction(),
            height: 220,
        });
    };


}