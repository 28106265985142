import {BaseWidget} from "./base-widget";

export class PaintWidget extends BaseWidget {

    constructor(controller) {
        super(controller);

        $(document).on('cft:insert-paint-widget', (event, data) => {
            controller.editor.replaceSelection(this._get_widget_js(data));
            controller.editor.focus();
        });
    }

    _get_widget_js(field) {
        return ` _sf[${field.id}].c='${field.color}'; `;
    }

    create(match) {
        const tpl = document.querySelector('template#scriptable-paint-field');
        const cft_dom_tpl = tpl.content.firstElementChild;

        let cft_dom = cft_dom_tpl.cloneNode(true);

        const field_name = this.controller.fieldsMap[match[1]];
        const field_color = match[2].trim();
        cft_dom.querySelector('[data-field-name]').textContent = field_name;
        cft_dom.querySelector('[data-field-color]').classList.add(`trello-${field_color}`);

        return cft_dom;
    }

    re(field) {
        return new RegExp(`\\s_sf\\s*\\[\\s*${field.id}\\s*\\]\\.c\\s*=\\s*\\'([^\\']+)\\';`, 'g');
    }

    reAll() {
        return /\s_sf\s*\[\s*(\d+)\s*\]\.c\s*=\s*\'([^\']+)\';/;
    }


}