import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../powerup/trello_ctx";
import Template from "../powerup/template"

export default class extends Controller {

    static targets = ['input'];
    #tpl = new Template(trello_ctx());

    async connect() {
        const tpl_id = await this.#tpl.id;
        this.inputTarget.value = tpl_id;
    }

    save(event) {
        event.preventDefault();
        const tpl_id = this.inputTarget.value;
        this.#tpl.id = tpl_id;
    }

}