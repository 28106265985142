import server from "./remote_data";
import trello_ctx from "./trello_ctx";

export default async function () {
    const params = new URLSearchParams(window.location.search)
    if (params.has('to')) {
        const destination_url = params.get('to');
        const t = trello_ctx();

        t.render ( async _ => {

            // do not redraw panels with focus
            //
            if (document.hasFocus()) {
                return;
            }

            const relativeUrl = window.TrelloPowerUp.util.relativeUrl(destination_url);
            const response = await server(t).ajax(relativeUrl);
            if (response.ok) {
                const result = await response.text();
                const iframe_container = document.querySelector('.js-trello-iframe-container');
                iframe_container.innerHTML = result;
                $('.js-trello-iframe-container').children('script').each((index, element) => eval(element.innerHTML));
                onmount();
                t.sizeTo(document.body)
                    .error( _=> {/* nop. suppress error when inivisible */});
            }
        });
    }
}
