import trello_ctx from "../powerup/trello_ctx";
import amplitude_client from "amplitude-js";

function getApiKey() {
    let meta;
    meta = document.querySelector('meta[name=amplitude-key]');
    return meta && meta.content;
}


let _amplitude_prop = null;

function _amplitude_getter() {
    if (null != _amplitude_prop) {
        return _amplitude_prop;
    }
    const t = trello_ctx();
    const board = t.getContext().board;
    const api_key = getApiKey();
    amplitude_client.getInstance().init(api_key, board);
    _amplitude_prop = amplitude_client;
    return _amplitude_prop;
}

function init() {
    Object.defineProperty(window, 'amplitude', {get: _amplitude_getter});
}


$(document).on('turbolinks:load',init);