import tracker from "../amplitude-tracker";
import routes from "../routes";
import server from "../remote_data";


export default function(t, options) {
    tracker(t).track('powerup.enabled');
    _report_enabled(t);
}

function _report_enabled(t) {
    const url = routes(t).board_enabled();
     server(t).ajax(url);
}