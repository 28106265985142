import settings_icon from "../../images/sigma-svgrepo-com.svg";
import logger from "../logger";
import Template from "../template";
import ui from "../ui";

export default async function(t, options) {
    logger.log('>>> board-buttons');

    try {
        return _board_buttons(t, options)
    }
    finally {
        logger.log('<<< board-buttons');
    }
}


async function _board_buttons(t, opts) {
    const tpl = new Template(t);
    await tpl.ensureSet();

    const btn = {
        icon: window.TrelloPowerUp.util.relativeUrl(settings_icon),
        text: t.localizeKey("p_name"),
        callback: t => ui(t).board_menu()
    };

    return [btn];
}
