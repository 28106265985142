let _last_update = null;
let cached_result = null;
const _ttl =  1000; //ms
export function cached_cards(t) {


    if (cached_result && _last_update && (performance.now()-_last_update)< _ttl) {
        return cached_result;
    }

    _last_update = performance.now();
    cached_result = new Promise((resolve) => {
        t.cards('id', 'idList', 'pos').then((ll) => {
            const list_top_cards = ll.reduce(reducer, {});
            resolve(list_top_cards);

        });
    });
    return cached_result;
}


function reducer(result, card) {
    let list = result[card.idList];
    if (!list) {
        result[card.idList] = {min_pos:Number.MAX_SAFE_INTEGER, top_card_id:null};
        list = result[card.idList];
    }

    if (card.pos<list.min_pos) {
        list.min_pos = card.pos;
        list.top_card_id = card.id
    }
    return result;
}
