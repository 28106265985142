export class BaseWidget {
    constructor(controller) {
        this.controller = controller;
    }


    create(match) {
        // return node to replace
        //
        throw new Error('IMPLEMENT: return dom node for the widget');
    }

    re(field) {
        throw new Error('IMPLEMENT: regex that will match the widget');
    }

    reAll() {
        throw new Error('IMPLEMENT: regex that will match all widgets');
    }


}