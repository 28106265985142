import routes from "../routes";
import server from "../remote_data";

export default async function(t, opts) {

  const resp = await server(t).ajax(routes(t).board_list_sorters());
  const json = await resp.json();
  const result = json.map(function(listSorter) {
      return {
        text: listSorter.name,
        callback: cardsSorter(listSorter)
      }
    });

  console.log(result);
  return result
}


function cardsSorter(listSorter) {
  return async function (t, opts) {

    const cardsInfo = await Promise.all(opts.cards.map( async card => {
      return {id: card.id, sfInfo: await t.get(card.id, 'shared', __CFT_DATA__) }
    }));

    const sortable =   cardsInfo.filter(card => !!card.sfInfo && !!card.sfInfo[listSorter.field_id]);
    const unsortable = cardsInfo.filter(card => !card.sfInfo  || !card.sfInfo[listSorter.field_id]);


    let ascComparator = (a,b) => a.sfInfo[listSorter.field_id].v - b.sfInfo[listSorter.field_id].v;
    if ('date' == listSorter.field_type) {
      ascComparator = (a, b) => {
        // convert to dates, compare x.getTime()
        const v1 = a.sfInfo[listSorter.field_id].v;
        const v2 = b.sfInfo[listSorter.field_id].v;

        const dv1 = new Date(v1);
        const dv2 = new Date(v2);

        return dv1.getTime() - dv2.getTime();
      }
    }
    else if ('text' == listSorter.field_type) {
      ascComparator = (a,b) => {
        const v1 = a.sfInfo[listSorter.field_id].v;
        const v2 = b.sfInfo[listSorter.field_id].v;

        const comp = v1 < v2;
        return comp ? -1 : 1;
      }
    }

    const comparator = listSorter.asc ? ascComparator : (a,b) => -1 * ascComparator(a,b);
    const sortedCards = sortable.sort(comparator);

    const allCards = listSorter.asc ?  unsortable.concat(sortedCards) : sortedCards.concat(unsortable);
    const sortedIds = allCards.map(function (c) { return c.id })

    return { sortedIds: sortedIds }
  }
}