import server_data from "./remote_data";
import routes from "./routes";
import trello_ctx from "./trello_ctx";

export default function() {
    const t = trello_ctx();
    const __CFT_DATA__ = '__CFT_DATA__';

    window.dataform.addEventListener('submit', async function(event){
        event.preventDefault();

        let list, member;
        [list, member] = await Promise.all([t.list('id','name'), t.member('all')] );

        let vars = {};
        let formData = new FormData(document.getElementById('dataform'))
        for (let v of formData.entries()) {
            vars[v[0]] = v[1];
        }

        const data = {list: list, member: member, vars: vars};

        const response = await server_data(t).ajax(routes(t).card(),
            {method: 'PUT',
                headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify(data)}
        );

        if (response.ok) {
            const json = await response.json();
            await t.set('card', 'shared', __CFT_DATA__, json);
            t.closePopup();
        }
        else {
            const errMsg = await response.text();
            $('.error-message').text(errMsg);
            $('.edit-footer').addClass('error');
            t.sizeTo('#dataform');
        }
    });
}
