/**
 * This is a bridge between Trello JWT and Rails UJS framwework.
 * It works by inserting a Trello JWT Authorization header into every AJAX request initiated by
 * Rails UJS.
 *
 * The token obtained from Trello periodically before tokens 15 minutes expiration time.
 *
 */
import trello_ctx from "../powerup/trello_ctx";
import jwt_token from "../powerup/jwt_token";
import { decode } from 'js-base64';

let jwtoken;

export function addTrelloJwtAuth(xhr) {
    xhr.setRequestHeader('Authorization', 'Bearer ' + jwtoken);
    return true;
}


export async function withJwtHeader(options) {
    const t = trello_ctx();
    const jwtoken = await jwt_token(t);
    options.headers = options.headers || {};
    options.headers['Authorization'] = 'Bearer ' + jwtoken;
    return options;
}



onmount('.js-trello-jwt-ujs', _update_token);

function  _update_token() {
    const t = trello_ctx();
    jwt_token(t).then( (token) => {
        const nowInSeconds = Math.ceil(Date.now()/1000);
        const payload = token.split('.')[1];
        const decoded = decode(payload);
        const parsedToken = JSON.parse(decoded);
        const expirationAt = parsedToken.exp;
        const validForSeconds = expirationAt - nowInSeconds;

        console.debug('Got Trello JWT token valid for (sec):', validForSeconds);
        setTimeout( _update_token, validForSeconds*1000);
        jwtoken = token;
        $(document).trigger('cft:jwt_updated')
    });
}

$(document).on('ajax:beforeSend', (event) => {
    const [xhr, opts] = event.detail;
    addTrelloJwtAuth(xhr);
});

document.addEventListener("turbolinks:request-start", function(event) {
    const xhr = event.data.xhr;
    addTrelloJwtAuth(xhr);
});
