// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"


window.Stimulus = Application.start()
const context = require.context(".", true, /_controller\.js$/)
Stimulus.load(definitionsFromContext(context))
