import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'
import trello_ctx from "../powerup/trello_ctx";

export default class extends Controller {
    connect() {
        useIntersection(this);
        this.resize = false;
    }

    appear(entry) {
        if (this.resize) {
            const t = trello_ctx();
            t.sizeTo(this.element);
        }
    }

    disappear(entry) {
        this.resize = true;
    }
}