import {BaseWidget} from "./base-widget";

export class FieldWidget extends BaseWidget {

    constructor(controller) {
        super(controller);

        $(document).on('cft:insert-field-widget', (event, data) => {
            controller.editor.replaceSelection(this._get_widget_js(data));
            controller.editor.focus();
        });
    }

    _get_widget_js(field) {
        if ('simple' == field.type ) {
            return ` _sf[${field.id}].v = `;
        }
        else if ('acc' == field.type ) {
            return ` _sf[${field.id}].v = _sf[${field.id}].v + `;
        }
        else if ('sub' == field.type ) {
            return ` _sf[${field.id}].v = _sf[${field.id}].v - `;
        }
        return ` _sf[${field.id}].v `;
    }

    create(match) {
        const tpl = document.querySelector('template#scriptable-field');
        const cft_dom_tpl = tpl.content.firstElementChild;

        let cft_dom = cft_dom_tpl.cloneNode(true);
        const field_name = this.controller.fieldsMap[match[1]];
        cft_dom.textContent = field_name;
        return cft_dom;
    }

    re(field) {
        return new RegExp(`\\s_sf\\s*\\[\\s*${field.id}\\s*\\]\\.v\\s`, 'g');
    }

    reAll() {
        return /\s_sf\s*\[\s*(\d+)\s*\]\.v\s/;
    }


}