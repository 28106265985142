export async  function replace_csv_placeholders(t, text) {
    const [lists, board] = await Promise.all(
        [t.lists('id', 'name'),t.board('members')]);

    const info = {};
    lists.forEach( l => info[l.id] = strip_list_totals(l.name));
    board.members.forEach(m => info[m.id] = m.fullName);
    return _replace_placeholders(info, text);
}


export function strip_list_totals(name) {
    const bulletIdx = name.indexOf('\u2022');
    return -1 == bulletIdx ? name: name.substring(0, bulletIdx).trim();
}


const MISSED_RE = /\{\{\w+\|\|([^\}]+)\}\}/g;
function _replace_placeholders(info, text) {
    let result = text;

    // replace known info
    //
    for (const id in info) {
        const name = info[id];
        const repl_regex = new RegExp(`\\\{\\{${id}\\|\\|[^\\}]+\\}\\}`, "g");
        result = result.replaceAll(repl_regex, name);
    }

    // replace unknown info
    //
    result = result.replaceAll(MISSED_RE, "$1");
    return result;
}


export function isEmpty(obj) {
    if (obj) {
        for (const x in obj) { return false; }
    }
    return true;
}
