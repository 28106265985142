import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import {addTrelloJwtAuth, withJwtHeader} from "../behaviors/trello-jwt";

export default class extends Controller {

    static targets = ['trigger', 'content'];

    connect() {

        const contentTarget = this.contentTarget;
        let options =  {
            content: contentTarget,
            interactive: true,
            trigger: 'click',
            placement: 'bottom-end',
            theme: 'light',
            arrow: true,
            interactiveBorder: 30,
        };

        if (this.data.get('parentBody')) {
            options.appendTo = document.body;
        }

        const contentUrl = this.data.get('url');
        if (contentUrl) {
            options = Object.assign(options, {
                async onShow(instance) {
                    let pjax_content;
                    const response = await fetch(contentUrl, await withJwtHeader({}));
                    if (response.ok) {
                        pjax_content = await response.text();
                    }
                    contentTarget.innerHTML = pjax_content;
                    instance.setContent(contentTarget);
                },
            });

        }


        this.instance = tippy(this.triggerTarget, options);
    }

    hide() {
        this.instance.hide();
    }

    disconnect() {
        this.instance.destroy();
    }

    _prepareStaticOptions() {
        return {
            content: this.contentTarget,
            interactive: true,
            trigger: 'click',
            placement: 'bottom-end',
            theme: 'light',
            arrow: true,
            interactiveBorder: 30,
            appendTo: document.body
        }
    }
}