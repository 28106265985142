import amplitude from 'amplitude-js';

let amplitude_instance = null;

export default function(t) {

    return {track};

    function track(event_type) {
        _instance(t).logEvent(event_type);
    }

    function _instance(t) {
        if (null != amplitude_instance) {
            return amplitude_instance;
        }

        const board = t.getContext().board;
        const api_key = _getApiKey();
        amplitude.getInstance().init(api_key, board);
        amplitude_instance = amplitude.getInstance()
        return amplitude_instance;
    }


    function _getApiKey() {
        let meta;
        meta = document.querySelector('meta[name=amplitude-key]');
        return meta && meta.content;
    }


}