/**
 * Controller for the the "little blue" field boxes that can be dragged into formulas
 * Handles:
 *  - drag and drop into script
 *  - field renames
 *  - field deletions
 */
import { Controller } from "@hotwired/stimulus"
import * as tippy from "tippy.js";

export default class extends Controller {

    connect() {
    }

    insert(event) {
        $(document).trigger('cft:insert-field-widget',{id:this.data.get("id")});
    }

    insert_simple(event) {
        $(document).trigger('cft:insert-field-widget',{id:this.data.get("id"), type: 'simple'});
    }

    insert_acc(event) {
        $(document).trigger('cft:insert-field-widget',{id:this.data.get("id"), type: 'acc'});
    }

    insert_sub(event) {
        $(document).trigger('cft:insert-field-widget',{id:this.data.get("id"), type: 'sub'});
    }


    color(event) {
        const color = event.target.value;
        const id = this.data.get('id');
        $(document).trigger('cft:insert-paint-widget',{id, color});
    }

    hide_menu(event) {
        // kinda hacky for now
        tippy.hideAll();
    }


}