let logger = {log: ()=> {}}
if (__CFT_DO_LOG__) {
    logger = {log: _log};
}


function _log () {
    console.log(...['[SF]', ...arguments]);
}

export default logger;
