import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../powerup/trello_ctx";
import {strip_list_totals} from "../utils/tmisc";

export default class extends Controller {

    static targets = ['focus', 'input', 'lock', 'toggle'];

    connect() {
    }


    unlock(event) {
        event.preventDefault();

        // remove disabled and readonly
        this.inputTargets.forEach( input => {
            input.removeAttribute('disabled');
        });

        // hide unlock buttons
        this.lockTargets.forEach( lock => {
            lock.classList.add('hidden');
        });


        // hide unlock buttons
        this.toggleTargets.forEach( toggle => {
            toggle.classList.toggle('hidden');
        });


        if (this.hasFocusTarget) {
            this.focusTarget.focus();
        }
    }
}
