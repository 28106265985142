import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['target'];

    connect() {
        this.defaultOptions = this._getElementOptions(this.element);
    }

    toggle(event) {
        this.targetTargets.forEach( target => {
            let targetOptions = this._getTargetOptions(target);
            targetOptions.toggle?.forEach( cls => {
                target.classList.toggle(cls);
            });
        });
    }

    _getTargetOptions(element) {
        return Object.assign(this.defaultOptions,
            this._getElementOptions(element) );
    }

    _parseClassList(list) {
        return list?.split(/\s+/);
    }

    _getElementOptions(element) {
        const result = {};
        const options = ['Add', 'Remove', 'Toggle'];

        options.forEach( option => {
           const value =  this._parseClassList(element.dataset[`classlist${option}`]);
           if (value) {
               result[option.toLowerCase()] = value;
           }
        });

        return result;
    }
}