import {Controller} from "@hotwired/stimulus";
import {withJwtHeader} from "../behaviors/trello-jwt";
import trello_ctx from "../powerup/trello_ctx";

/*
    <button data-controller='authorize'
        data-authorize-save-token-url="/account/xxx"
        data-authorize-api-key="/account/xxx"
        data-action="authorize#start"
    </button>
 */

const tokenLooksValid = token => /^[0-9a-f]{64}$/.test(token);

export default class extends Controller {

    connect() {
    }

    async start(event) {
        event.preventDefault();

        const apiKey = this.data.get('apiKey');
        const saveTokenUrl = this.data.get('saveTokenUrl');
        const returnUrl = this.data.get('returnUrl');
        const t = trello_ctx();


        // create trello authorization url
        const trelloAuthParams = {
            expiration: 'never',
            name: 'SmartFields',
            scope: 'read,write,account',
            key: apiKey,
            callback_method: 'fragment',
            return_url: returnUrl
        };

        const opts = {
            height: 750,
            width: 620,
            validToken: tokenLooksValid,
        };

        window['on_trello_authorized'] = this._accept_token;
        const trelloAuthUrl = `https://trello.com/1/authorize?${new URLSearchParams(trelloAuthParams).toString()}`;
        const token = await t.authorize(trelloAuthUrl, opts);
        const formData = new FormData();
        formData.append('token', token);
        let response = await fetch(saveTokenUrl,
            await withJwtHeader({
                method: 'POST',
                body: formData
            }));
        if (response.ok) {
            $(document).trigger('cft:authorized')
        }
    }


    _accept_token() {
        window.opener.authorize(token);
        return window.close();
    };

}
