import icon from '../../images/history.svg'
import PluginData from "../plugin_data";

export default async function(t, options){
    const data = new PluginData(t);
    const board_data = await data.board;

    console.log('got bd', board_data);
    if (!board_data || !board_data.display_history) {
        return null;
    }

    return {
        title: t.localizeKey("history_title"),
        icon: window.TrelloPowerUp.util.relativeUrl(icon),
        content: {
            type: 'iframe',
            url: window.TrelloPowerUp.util.relativeUrl(t.signUrl('powerup/history')),
            height: 200
        }
    }
}

