import { Controller } from "@hotwired/stimulus"
import smartlookClient from 'smartlook-client'
import trello_ctx from "../powerup/trello_ctx";

export default class extends Controller {
    async connect() {
        smartlookClient.init('840e9b7c43d7397706deb171e03e3f438324ff51');

        const t = trello_ctx();
        const board = await t.board('id','name');

        smartlookClient.identify(board.id, {name: board.name});
    }
}