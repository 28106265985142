import settings_icon from "../../images/sigma-svgrepo-com.svg";

import server from "../remote_data";
import ui from "../ui";
import PluginData from "../plugin_data";
import {isEmpty} from "../../utils/tmisc";


export default async function(t, options) {


    const pdata = new PluginData(t);
    const board_data = await pdata.board;

    if ( isEmpty(board_data) ) {
        return [];
    }

    return [{
        icon: window.TrelloPowerUp.util.relativeUrl(settings_icon),
        text: t.localizeKey("p_name"),
        callback: card_menu,
    }];
}

async function card_menu(t) {
    const member = await server(t).member();
    let menu_items = [
        {
            text: t.localizeKey("download_csv"),
            callback: (t,opts) => server(t).download_csv()
        }
    ];

    if ("admin" == member.membership) {
        menu_items.push( {
            text: t.localizeKey("delete_last_tx_menu"),
            callback: confirm_delete_last_values
        });

        menu_items.push( {
            text: t.localizeKey("delete_history_menu"),
            callback: confirm_delete_history
        });

        menu_items.push( {
            text: 'Card Settings',
            callback: t => ui(t).card_settings()
        });

    }

    t.popup({
        title: t.localizeKey("p_name"),
        items: menu_items,
    });
}


function confirm_delete_last_values(t, opts) {
    return t.popup({
       type: 'confirm',
       title: t.localizeKey("delete_confirm_title"),
       message: t.localizeKey("confirm_delete_last_tx"),
       confirmText: t.localizeKey("delete_last_tx_btn"),
       confirmStyle: 'danger',
       onConfirm: delete_last_values
    });
}

function delete_last_values(t, opts) {
    server(t).delete_card_last_tx();
    t.closePopup();
}


function confirm_delete_history(t, opts) {
    return t.popup({
        type: 'confirm',
        title: t.localizeKey("delete_confirm_title"),
        message: t.localizeKey("confirm_delete_history"),
        confirmText: t.localizeKey("delete_history_btn"),
        confirmStyle: 'danger',
        onConfirm: delete_history
    });
}

function delete_history(t, opts) {
    server(t).delete_card_history();
    t.closePopup();
    t.set('card', 'private', '_sf_trigger', new Date().getTime());
}
