import {isEmpty} from "../utils/tmisc";
import server from "./remote_data";

export default class PluginData {
    static  __CFT_DATA__ = '__CFT_DATA__';

    constructor(t) {
        this.t = t;
    }

    get board() {
        return this._get_board();
    }

    get card() {
        return this.t.get('card', 'shared', PluginData.__CFT_DATA__);
    }

    trigger_capabilities() {
        console.log('triggering Smart Fields capabilities');
        return this.t.set('board', 'shared', '__CFT_TRIGGER__', new Date().getTime());
    }


    async reload_all() {
        const data = await this._get_board_json(true);

        return Promise.all([
            this._set_board_data(data),
            this._set_cards_data(data)
        ]);
    }

    async reload_board() {
        const data = await this._get_board_json(false);
        await this._set_board_data(data);
        console.log('reloaded board Smart Fields plugin data', data);
        return data;
    }


    ////// PRIVATE /////////


    async _get_board() {
        let data = await this.t.get('board', 'shared', PluginData.__CFT_DATA__);

        const hasBoardId = !isEmpty(data) && data.hasOwnProperty('board_id');
        if (!hasBoardId) {
            // try to reload board data with a "fresher" schema version
            data = this.reload_board();
        }

        const validDataPresent = !isEmpty(data)
            && data.board_id == this.t.getContext().board;
        if (validDataPresent) {
            return data;
        }

        return {};
    }


    async _set_board_data(data) {
        let data_without_cards = Object.assign({}, data);
        delete data_without_cards.cards;
        return this.t.set('board', 'shared', PluginData.__CFT_DATA__, data_without_cards);
    }

    async _set_cards_data(data) {
        const cards = await this.t.cards('id');
        const cards_data = data.cards;
        const pp = cards.map(
            c => {
                const data = cards_data.find( d => c.id == d.id );
                if (data) {
                    return this.t.set(c.id, 'shared', PluginData.__CFT_DATA__, data);
                }
                else {
                    return this.t.remove(c.id, 'shared', PluginData.__CFT_DATA__)
                }
            } );
        return Promise.all(pp);
    }


    async _get_board_json(with_data=false) {
        const url = PluginData._getBoardDataUrl();
        const response = await server(this.t).ajax(`${url}?with_data=${with_data}`);
        let result = {};
        if (response.ok) {
            result = await response.json();
        }
        return result;
    }

    static _getBoardDataUrl() {
        const meta = document.querySelector('meta[name=board-data]');
        return meta && meta.getAttribute('content');
    }


}

