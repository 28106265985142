const TPL_ID = '__sf_template__id__';
export default class Template {

    #t;

    constructor(t) {
        this.#t = t;
    }

    get id() {
        return this.#t.get('board', 'shared', TPL_ID);
    }

     set id(new_id) {
        return this.#t.set('board', 'shared', TPL_ID, new_id);
    }

    async reset() {
        const this_board = this.#t.getContext().board;
        this.id = this_board;
    }

    async ensureSet() {
        const currentId = await this.id;
        if (!currentId) {
            await this.reset();
        }
    }
}
