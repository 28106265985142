import sigma from "../images/sigma-svgrepo-com.svg";
import PluginData from "./plugin_data";
import {isEmpty} from "../utils/tmisc";

const AGGREGATORS = {
    'show_sum': vv => vv.reduce((sum, v)=> sum+v),
    'show_min': vv => Math.min(...vv),
    'show_max': vv => Math.max(...vv),
    'show_avg': vv => vv.reduce((sum, v)=> sum+v)/vv.length,
};


export default function(t) {

    return {card_badges};

    async function card_badges() {
        const data = new PluginData(t);
        const board_data = await data.board;
        if (isEmpty(board_data) || !board_data.summary_top_card ) {
            return [];
        }

        const ordered_fields = board_data.vars.order.map( id => {
          return {id:id, field:board_data.vars[id]}
        });

        const summary_fields = ordered_fields.reduce((a, vd) => {
            const show_opt = vd.field.options.list_total;
            if ('show_none' != show_opt)
                a.push(vd);
            return a;
        }, []);

        if (0==summary_fields.length) {
            return [];
        }

        const result = summary_fields.map((field) => {
            return {
                dynamic: () => _field_summary(t, field, board_data)
            }
        });

        return result;
    }


    async function _field_summary(t, field, board_data) {

        const sigmaIcon = window.TrelloPowerUp.util.relativeUrl(sigma);

        const list = await t.list('all');
        const cards_in_list = list.cards;
        const card_data_promises = cards_in_list.reduce((a, c) => {
            a.push(t.get(c.id, 'shared', __CFT_DATA__))
            return a;
        } , []);

        const card_data = await Promise.all(card_data_promises);
        const cards_with_values = card_data.filter(c => c);

        let values = [];
        cards_with_values.forEach((card) => {
            const value = card[field.id];
            if (value) {
                values.push(value.v);
            }
        });

        const number_formatter = new Intl.NumberFormat(window.locale);
        const aggr = AGGREGATORS[field.field.options.list_total];
        let summary = '-';
        if ( 0 < values.length) {
            summary = number_formatter.format(aggr(values));
        }

        const text = t.localizeKey(field.field.options.list_total, {name:field.field.l, value:summary});
        const result = {
            text: text,
            icon: sigmaIcon,
            color: board_data.summary_color,
            refresh: 10
        };
        return result;
    }

}