import card_back_section from './capabilities/card_back_section'
import card_badges from './capabilities/card_badges'
import card_detail_badges from "./capabilities/card_detail_badges";
import board_buttons from "./capabilities/board_buttons";
import card_buttons from "./capabilities/card_buttons";
import on_enable from "./capabilities/on_enable";
import list_sorters from "./capabilities/list_sorters";

import loadLocalizer from "./localization";
import tracker from "./amplitude-tracker";
import list_actions from "./capabilities/list-actions";
import ui from "./ui";

export default function init() {
    TrelloPowerUp.initialize({
        'board-buttons':        board_buttons,
        'card-buttons':         card_buttons,
        'card-back-section':    card_back_section,
        'card-badges':          card_badges,
        'card-detail-badges':   card_detail_badges,
        'list-sorters':         list_sorters,
        'on-enable':            on_enable,
        'on-disable':            (t, opts) => {
            tracker(t).track('powerup.disabled');
        },
        'show-settings': (t, opts) => ui(t).board_menu(),
        'list-actions': list_actions,
    }, {
        helpfulStacks: true,
        loadLocalizer: loadLocalizer
    });
}
