

export async function init(controller) {

    const textArea = controller.element;

    // import all libs
    const [CodeMirror, { JSHINT }] = await Promise.all ([
        import("codemirror/lib/codemirror"),
        import("jshint/dist/jshint"),
        import("codemirror/addon/search/searchcursor"),
        import("codemirror/mode/javascript/javascript"),
        import("codemirror/addon/lint/lint"),
        import("codemirror/addon/lint/javascript-lint"),
        import("codemirror/addon/scroll/simplescrollbars"),
    ]);
    window.JSHINT = JSHINT;

    const $textarea = $(textArea);

    let readOnly = false;
    if ($textarea.data('read-only')) {
        readOnly =  'nocursor';
    }

    const _jsEditor = CodeMirror.fromTextArea(textArea, {
            mode: "javascript",
            theme: 'xq-light',
            viewportMargin: Infinity,
            lineWrapping: true,
            lineNumbers: true,
            scrollbarStyle: "simple",
            readOnly,
            gutters: ["CodeMirror-lint-markers"],
            lint: {
                'esversion': '6', 'nocomma':true, 'undef': true, 'varstmt': true,
                'asi': true,
                'globals': {'_sf': true},
                'sub': true
            }
        }
    );

    $(document).on('cft:field-change', (event, data)=>{
        if (!data.changes.hasOwnProperty('label'))
            return;
        controller.fieldsMap[data.id] = data.changes.label[1];
        _clearWidgets (_jsEditor);
        CodeMirror.signal(_jsEditor, 'changes');
    });

    $(document).on('cft:field-created', (event, data)=>{
        controller.fieldsMap[data.id] = data.name;
    });

    return _jsEditor;
}

function _clearWidgets (editor) {
    editor.getAllMarks().forEach ( (marker) => {
        const wn = marker.replacedWith;
        marker.clear();
    } );
}