import en from './locales/en.json'
import ru from './locales/ru.json'


export default function loadLocalizer(locale) {

    let res = en;
    if ('ru' === locale) {
        res = ru;
    }
    return {localize:(key, data) => {
        return _localize(res[key], data);
    }}
}


function _localize(rawString, args) {
    if (args) {
        const holeRegex = /\{(\w+?)\}/gi;
        let formattedString = '';
        let hole = holeRegex.exec(rawString);
        let index = 0;
        while (hole) {
            if (hole.index > index) {
                formattedString += rawString.substring(index, hole.index);
            }
            if (args[hole[1]] !== undefined) {
                formattedString += args[hole[1]];
                index = hole.index + hole[0].length;
            } else {
                throw new i18nError.ArgNotFound(`Arg: ${hole[1]} was not defined for key: ${key}`);
            }
            hole = holeRegex.exec(rawString);
        }
        formattedString += rawString.substring(index);
        return formattedString;
    }
    return rawString;
}