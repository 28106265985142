import download from "downloadjs";

export default server;

import routes from "./routes";
import logger from "./logger";
import jwt_token from "./jwt_token";
import {isEmpty, replace_csv_placeholders} from "../utils/tmisc";
import PluginData from "./plugin_data";

function server(t) {

    return {ajax, member, get_card_data, download_csv, delete_card_history, delete_card_last_tx};


    async function ajax(url, options={}) {
        const jwt = await jwt_token(t);
        options.headers = options.headers || {};
        options.headers['Authorization'] = 'Bearer ' + jwt;

        return fetch(url, options);
    }

    async function member() {
        const ms_info = await t.board('memberships');

        // figure out membership on this board
        //
        const member_id = t.getContext().member;
        const ms = ms_info.memberships.find( (k)  => k.idMember == member_id);
        member.membership = ms ? ms.memberType : 'none';

        return member;
    }


    async function delete_card_history() {
        const url = routes(t).card_history();
        await server(t).ajax(url, {method: 'DELETE'});
    }

    async function delete_card_last_tx() {
        const url = routes(t).card_last_tx();
        const response = await server(t).ajax(url, {method: 'DELETE'});
        const json = await response.json();
        await t.set('card', 'shared', __CFT_DATA__, json);
    }


    async function download_csv() {
        const url = routes(t).card_history()+'.csv';
        const response = await server(t).ajax(url);
        if (response.ok) {
            const [blob, board, card] =
                await Promise.all([response.text(), t.board('name'), t.card('name')]);

            const final_csv = await replace_csv_placeholders(t, blob);

            download(final_csv, `${card.name} on ${board.name}.csv`, 'text/csv');
        }
    }




    async function get_card_data() {
        const pdata = new PluginData(t);
        const [board_data, card_data, list] = await Promise.all([
            pdata.board, pdata.card,
            t.list('id')
        ]);

        if (isEmpty(board_data) ) {
            return [];
        }


        if (isEmpty(card_data) ) {
            return [board_data];
        }

        // if data.card_id does not match current card_id
        // this card has been copied
        //

        // if data.card_id matches the current card_id
        // but data.board_id does not match the current board_id
        // this card has been moved

        const current = {board_id: t.getContext().board, id: t.getContext().card};
        const source = {board_id: card_data.board_id, id: card_data.id};
        const inSync = 'id' in card_data
            && 'board_id' in card_data
            && source.id === current.id
            && source.board_id === current.board_id
            ;
        if (inSync) {
            return [board_data, card_data]
        }

        // if card was copied or moved, sync data with server
        //
        const sync = {board_id: card_data.board_id, id: card_data.id, list_id: list.id};

        logger.log('calling card data sync', t.getContext().card);
        let response = await ajax(routes(t).card_sync() ,{method: 'POST',
            headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify({sync})
        });

        let result = [board_data];
        if (response.ok) {
            const new_card_data = await response.json();
            if (!isEmpty(new_card_data)) {
                result.push(new_card_data);
                await t.set('card', 'shared', __CFT_DATA__, new_card_data)
                logger.log('card data synced', t.getContext().card);
            }
            else {
                logger.log('card data not synced - will attempt to sync again', t.getContext().card);
            }
        }
        return result;
    }

}

