export default async function(t) {

    const [board, member, lists, cards] = await Promise.all( [
        t.board('memberships', 'name'),
        t.member('id','fullName'),
        t.lists('id'),
        t.cards('id'),
    ]);

    const membership = board.memberships.find( mm => mm.idMember == member.id);
    const memberType = membership ? membership.memberType : 'normal';


    const jwt_state = {
        memberName: member.fullName,
        memberType: memberType,
        boardName: board.name,
        membersCount: board.memberships.length,
        cardsCount: cards.length,
        listsCount: lists.length
    };

    return t.jwt( {state: JSON.stringify(jwt_state)} );
}