import server from "../remote_data";
import ui from "../ui";
import FieldFormatter from "../field_formatter";

export default async function (t, options) {


    const [member, [board_data, card_data]] = await Promise.all([
        server(t).member(),
        server(t).get_card_data()
    ]);

    if (!board_data) {
        return [
            {
                title: "",
                text: t.localizeKey("enable_p"),
                color: 'purple',
                callback: t => {
                    ui(t).intro();
                }
            }
        ];
    }
    let defs = board_data.vars
    let result = [];

    const has_fields = 0 != defs.order.length;
    if ("admin" == member.membership) {

        if (has_fields) {
            result.push({
                title: t.localizeKey('p_name')  + " \u25b6",
                text: "Customize",
                callback:  t => ui(t).card_menu(),
            });
        }
        else {
            result.push({
                title: t.localizeKey('p_name'),
                text: "New Field",
                color: 'blue',
                callback:  t => ui(t).new_field(),
            });

        }

    }

    const field_formatter = new FieldFormatter();
    for (let i=0; i<defs.order.length; i++) {
        let vd = defs.order[i];
        let def = defs[vd];

        if (!def.options.show_on_detail_badge) {
            continue;
        }

        let variable;
        if (typeof card_data != "undefined") {
            variable = card_data[vd];
        }


        let txt = '';
        let color = def.c;

        if (variable) {
            txt = field_formatter.getCardDetailBadge(def, variable);
            color = (typeof variable.c == "undefined") ? def.c : variable.c;

        }

        result.push({
            title: def.l,
            text: txt,
            color: color,
            callback: t => ui(t).data_entry()
        });
    }

    if (has_fields) {
        result.push({
            title: "\u25c0 " + t.localizeKey('p_name') ,
            text: t.localizeKey('data_entry_tip_enter'),
            callback: t => ui(t).data_entry(),
        });
    }

    return result;
}
