/**
 * NOTE: WORKS ONLY WITH RAILS UJS
 *
 * Mark a from with data-downloadble='filename' attribute
 * to cause the result of [data-remote] element downloaded
 * into file specified by the file name.
 */
import download from 'downloadjs';
import {replace_csv_placeholders} from "../utils/tmisc";
import trello_ctx from "../powerup/trello_ctx";

document.addEventListener('ajax:success', async (event)=>{
    if (event.target.matches('[data-downloadable]')) {
        const form_el = event.target;
        const [result] = event.detail;
        const filename = form_el.dataset.downloadable;

        const t = trello_ctx();
        const final_csv = await replace_csv_placeholders(t, result);

        download(final_csv, filename);
    }
});