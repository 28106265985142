import server from "../remote_data"
import {isEmpty} from "../../utils/tmisc";
import PluginData from "../plugin_data";
import ui from "../ui";

export default async function list_actions(t) {


    const pdata = new PluginData(t);
    const [member, board_data] = await Promise.all([
        server(t).member(),
        pdata.board
    ]);

    if (isEmpty(board_data) ) {
        return [];
    }

    let result = [];
    if ("admin" == member.membership) {
        result.push({
                text: 'List Summary',
                callback: t => ui(t).list_summary()
            });
    }

    result.push({
        text: 'List Sorters',
        callback: t => ui(t).list_sorters()
    });


    return result;
}

