import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../powerup/trello_ctx";
import routes from "../powerup/routes";

export default class extends Controller {

    connect() {
    }

    popup(event) {
        event.preventDefault();

        const options = this._get_popup_option();
        options.mouseEvent = event;
        const t = trello_ctx();
        t.popup(options);
    }



    navigate(event) {
        event.preventDefault();
        const t = trello_ctx();

        let url = this.data.get('url');
        if (!url) {
            url = this.element.getAttribute('href');
        }
        t.closePopup();
        t.navigate({url:url});
    }

    closePopup(event) {
        const t = trello_ctx();
        t.closePopup();
    }

    modal(event) {
        event.preventDefault();

        const options = this._get_popup_option();
        options.mouseEvent = event;
        const t = trello_ctx();
        t.modal(options);
    }

    closeModal(event) {
        const t = trello_ctx();
        t.closeModal();
    }


    back(event) {
        const t = trello_ctx();
        t.back();
    }


    size_to_body(event) {
        const t = trello_ctx();
        t.sizeTo(document.body);
    }



    _get_popup_option() {
        const t = trello_ctx();

        const options = {};
        options.url = this.data.get('url');
        if (!options.url) {
            options.url = this.element.getAttribute('href');
        }

        options.url = routes(t).iframe_url(options.url, false);

        options.title = this.data.get('title')
        if (!options.title) {
            options.title = this.element.getAttribute('title')
        }
        if (!options.title) {
            options.title = '';
        }

        const heightStr = this.data.get('height');
        if (heightStr) {
            options.height = parseInt(heightStr);
        }




        return options;
    }
}
