import LocalTime from "local-time"
import routes from "./routes";
import server from "./remote_data";
import loadLocalizer from "./localization";

LocalTime.start();

export default function() {
    const t = window.TrelloPowerUp.iframe({loadLocalizer: loadLocalizer});

    t.render ( async () => {
        t.localizeNode(document.documentElement);

        // get and show card history
        //
        const response = await server(t).ajax(routes(t).card_history());
        if (response.ok) {
            const result = await response.text();
            document.querySelector('[data-js-table-container]').innerHTML = result;
        }
    })
}
