import { Controller } from "@hotwired/stimulus"
import {init} from './codemirror/init'

import {FieldWidget} from './codemirror/field-widget'
import {PaintWidget} from "./codemirror/paint-widget";


export default class extends Controller {

    static targets = [];

    async connect() {
        this.fieldsMap = JSON.parse(this.data.get('fields'));

        this.editor = await init(this);

        this.widgets = [];
        this.widgets.push(new FieldWidget(this));
        this.widgets.push(new PaintWidget(this));

        $(document).on('cft:field-change cft:field-list-changed', this._onFieldsChanged.bind(this));

        $(document).on('cft:field-deleted', this._onFieldDeleted.bind(this));
        this.editor.on('changes', this._createWidgets.bind(this));
        this._createWidgets();

    }

    disconnect() {
        if (this.editor)
            this.editor.toTextArea();
    }

    _onFieldDeleted(event, data) {
        // replace the text
        let text = this.editor.getValue();

        this.widgets.forEach( (w) => {
            const re = w.re(data);
            text = text.replace(re, '');
        });

        this.editor.setValue(text);
    }


     _createWidgets() {
         this.widgets.forEach( (w) => {
             const reAll = w.reAll();
             const cursor = this.editor.getSearchCursor(reAll);
             let match = cursor.find();
             while ( match ) {
                 let widgetEl = w.create(match);
                 this.editor.markText(cursor.from(), cursor.to(), {replacedWith: widgetEl});
                 match = cursor.find();
             }
         });
    }


    _onFieldsChanged(event, data) {
        const fields_count = $("[data-target='edit-fields.handle']").length;
        const $intro = $('#formulas-intro-section');
        const $formula = $('#formulas-edit-section');

        if (0 === fields_count) {
            $formula.addClass('hidden');
            $intro.removeClass('hidden');
        } else {
            $formula.removeClass('hidden');
            $intro.addClass('hidden');
            this.editor.refresh();
        }
    }
}