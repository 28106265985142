import { Controller } from "@hotwired/stimulus"
import trello_ctx from "../powerup/trello_ctx";

export default class extends Controller {

    static targets = ['input', 'presentation'];
    static values = { placeholder: String, title: String }
    // add default placefolder value "+ Add a date"

    connect() {

        this._updatePresentation();
        // format presentation using Intl. package and trello locale
        const t = trello_ctx();

    }


    pick(event) {
        event.preventDefault();

        const dateValue = this.inputTarget.value;
        let currentDate = null;
        if (dateValue) {
            currentDate = new Date(dateValue);
        }

        const t = trello_ctx();
        t.popup({
            type: 'datetime',
            title: this.titleValue,
            callback: (t, opts) => {
                // opts.date is an ISOString
                this.inputTarget.value = opts.date;
                this._updatePresentation();
                t.back();
            },

            date: currentDate,
        })

    }


    clear(event) {
        event.preventDefault();
        this.inputTarget.value = '';
        this._updatePresentation();
    }

    _updatePresentation() {

        // read from input target and format presentation
        const dateValue = this.inputTarget.value;
        const newDate = new Date(dateValue);

        let localDate =this.placeholderValue;
        if (dateValue) {
            localDate = this._getDateFormatter().format(newDate);
        }
        this.presentationTarget.value = localDate;
    }

    _getDateFormatter() {
        return new Intl.DateTimeFormat(window.locale,
            {
                dateStyle: 'medium',
                timeStyle: 'short'
            }
        )
    }

}