import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [];

    connect() {
        const $this = $(this.element);
        const form = this.element;

        // // when the control changes save right away
        $this.on('change', 'select,input:not([type=text])', () => {
            this._submitForm(form);
        });

        // while typing save every
        //
        const dataDelay = 200;
        let submitTimeout = -1;
        $this.on('input','input[type=text]', ()=> {
            if(-1 != submitTimeout) {
                clearTimeout(submitTimeout);
                submitTimeout = -1;
            }
            submitTimeout = setTimeout(()=>this._submitForm(form), dataDelay);
            return;
        });

    }

    _submitForm(form) {
        // do not submit form with invalid elements
        const invalidInput = form.querySelector(':invalid');
        if (!invalidInput) {
            Rails.fire(form, 'submit');
        }

    }
}
