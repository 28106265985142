import {Controller} from "@hotwired/stimulus";
import trello_ctx from "../powerup/trello_ctx";
import {strip_list_totals} from "../utils/tmisc";

export default class extends Controller {

    async connect() {
        const t = trello_ctx();
        const tlists = await t.lists('id', 'name');

        for (const tl of tlists) {
            const newOption = document.createElement('option');
            newOption.value = tl.id;
            newOption.textContent = strip_list_totals(tl.name);
            this.element.appendChild(newOption);
        }
    }
}