import {Controller} from "@hotwired/stimulus";
import {withJwtHeader} from "../behaviors/trello-jwt";

/*
    Represents PJSX fragment of the page
    <div data-controller='pjax'
        data-pjax-url="/account/xxx"
        data-pjax-poll='500'
        data-action='pjax#refresh
        data-action='pjax#startPoll
        data-action='pjax#stopPoll>
        <!-- content will replaces with response from the url -->
    </div>
 */
export default class extends Controller {

    connect() {
        this.refresh();
        const pollStr = this.data.get('poll');
        this._pollInterval = pollStr ? parseInt(pollStr) : 1000;
    }

    startPoll() {
        this.stopPoll();
        this.watchInterval = setInterval(() => {
            this.refresh();
        }, this._pollInterval)
    }

    stopPoll() {
        if (this.watchInterval) {
            clearInterval(this.watchInterval);
            this.watchInterval = null;
        }
    }

    async refresh(event) {
        let url = this.data.get('url');

        let pjax_content;
        let opts = await withJwtHeader({});
        const response = await fetch(url, opts );
        if (response.ok) {
            pjax_content = await response.text();
        }
        // replace content only if it is different from previous
        // why not compare with this.element.innerHTML?
        //  - to eliminate difference in quotes
        //  - to eliminate difference in JS changed attributes or elements
        //
        if (this._currentContent != pjax_content) {
            this._currentContent = pjax_content;
            this.element.innerHTML = pjax_content;
        }
    }
}