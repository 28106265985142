import {Controller} from "@hotwired/stimulus";
import trello_ctx from "../powerup/trello_ctx";
import {strip_list_totals} from "../utils/tmisc";

export default class extends Controller {

    static targets = ['list'];

    async connect() {

        const t = trello_ctx();
        const tlists = await t.lists('id', 'name');

        this.listTargets.forEach( l => {
            const trello_id = l.dataset.trelloId;
            const tl = tlists.find( l => l.id == trello_id);

            if (tl) {
                l.textContent = strip_list_totals(tl.name);
            }
        });
    }
}